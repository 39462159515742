(function ($) {
    $(document).ready(function () {

        $('.header-slider').owlCarousel({
            //animateIn: 'zoomIn',
            //animateOut: 'zoomOut',
            autoplay: true,
            loop: true,
            margin: 15,
            nav: true,
            dots: true,
            items: 3,
            slideBy: 3,
            navText: [
                '<i class="fa fa-arrow-left"></i>',
                '<i class="fa fa-arrow-right"></i>'
            ]
        });


        /*
         * Replace all SVG images with inline SVG
         */
        $('img.js-svg').each(function () {
            var $img = jQuery(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            jQuery.get(imgURL, function (data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find('svg');

                // Add replaced image's ID to the new SVG
                if (typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if (typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass + ' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');

        });
        if ($(".map_canvas").length) {

            $maps = jQuery('.map_canvas');
            $maps.each(function (index, Element) {
                $infotext = $(Element).children('.infotext');

                var Style = [{
                    featureType: 'poi',
                    elementType: 'geometry',
                    stylers: [{hue: '#FFDEBE'}, {saturation: 100}, {lightness: 42}, {visibility: 'on'}]
                }, {
                    featureType: 'poi.business',
                    elementType: 'geometry',
                    stylers: [{hue: '#DCBBAE'}, {saturation: 29}, {lightness: -9}, {visibility: 'on'}]
                }, {featureType: 'water', elementType: 'all', stylers: []}, {
                    featureType: 'landscape.natural',
                    elementType: 'geometry',
                    stylers: [{hue: '#A6CCA2'}, {saturation: 17}, {lightness: -24}, {visibility: 'on'}]
                }, {
                    featureType: 'road.local',
                    elementType: 'geometry',
                    stylers: [{hue: '#ffffff'}, {saturation: -100}, {lightness: 100}, {visibility: 'on'}]
                }, {
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [{hue: '#FFFFCC'}, {saturation: 100}, {lightness: 72}, {visibility: 'on'}]
                }, {featureType: 'water', elementType: 'all', stylers: []}, {
                    featureType: 'poi.park',
                    elementType: 'geometry',
                    stylers: [{hue: '#A6CCA2'}, {saturation: -32}, {lightness: -8}, {visibility: 'on'}]
                }];

                var pinkMapType = new google.maps.StyledMapType(Style,
                    {
                        name: "Style"
                    });

                Smaptypex = $infotext.children('.maptype').text();

                var myOptions = {
                    'zoom': parseInt($infotext.children('.zoom').text()),
                    //'styles': Style,
                    'mapTypeId': google.maps.MapTypeId[Smaptypex]
                };
                var map;
                var geocoder;
                var marker;


                var infowindow;
                var address = $infotext.children('.address').text() + ', '
                        + $infotext.children('.city').text() + ', '
                        + $infotext.children('.state').text() + ' '
                        + $infotext.children('.zip').text() + ', '
                        + $infotext.children('.country').text()
                    ;


                var mcontent = '<strong>' + $infotext.children('.location').text() + '</strong><br />'
                        + $infotext.children('.address').text() + '<br />'
                        + $infotext.children('.zip').text() + ', '
                        + $infotext.children('.city').text() + ', '
                        + $infotext.children('.state').text()
                    ;
                if (0 < $infotext.children('.phone').text().length) {
                    mcontent += '<br />' + $infotext.children('.phone').text();
                }

                geocoder = new google.maps.Geocoder();
                geocoder.geocode({'address': address}, function (results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        myOptions.center = results[0].geometry.location;
                        map = new google.maps.Map(Element, myOptions);
                        marker = new google.maps.Marker({
                            map: map,
                            //icon: '/assets/img/marker.png',
                            position: results[0].geometry.location,
                            title: $infotext.children('.location').text()
                        });
                        infowindow = new google.maps.InfoWindow({'content': mcontent});
                        google.maps.event.addListener(map, 'tilesloaded', function (event) {
                            infowindow.open(map, marker);
                        });
                        google.maps.event.addListener(marker, 'click', function () {
                            infowindow.open(map, marker);
                        });
                    } else {
                        alert('Die Adresse konnte aus folgenden Gründen nicht gefunden werden: ' + status);
                    }
                });
            });
        }
    });
}(jQuery));
